<template>
    <div class="lvya-newsDetails">
         <div class="news-tab" style="padding: 180px 0 0 0;">
            <ul class="tab-tilte">
                <li @click="cur=0,hrefJyzx()" :class="{active:cur==0}">教育资讯</li>
                <li @click="cur=1,hrefHydt()" :class="{active:cur==1}">行业动态</li>
            </ul>
         </div>
        <!-- <div class="newsDetails-top">
            <p @click="hrefJyzx()">教育咨询</p>
            <p @click="hrefHydt()">行业动态</p>
        </div> -->
        <div class="details-div">
            <p class="details-p1">{{details.title}}</p>
            <p class="details-p2">时间：{{details.ctime | formatDate }}</p>
            <p class="details-p3" v-html="details.text"></p>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import { formatDate } from './js/time'
export default {
    components: {
    },
    data() {
        return {
            details: '',
            cur: this.$route.query.cur
        }
    },
    filters: {
        formatDate(time) {
            // time = time * 1000
            let date = new Date(time)
            console.log(new Date(time))
            return formatDate(date, 'yyyy-MM-dd hh:mm')
        }
    },
    methods: {
        getId() {
            this.id = this.$route.query.id
        },
        getDetail() {
            let origin = /localhost/.test(window.location.href) ? window.GateWayServer : location.origin
            console.log(origin, '1111')
            axios.post(`${origin}/api/news/` + this.id)
            .then(res => {
                res.data.text = this.escape2Html(res.data.text)
                this.details = res.data
                console.log(this.details)
            })
        },
        escape2Html(str) {
            const arrEntities = { 'lt': '<', 'gt': '>', 'nbsp': ' ', 'amp': '&', 'quot': '"' }
            return str.replace(/&(lt|gt|nbsp|amp|quot);/ig, function(all, t) { return arrEntities[t] })
        },
        hrefUrl(id) {
            this.$router.push({ path: '/news' })
        },
        prev() {
            this.$router.go(-1)
        },
        hrefJyzx() {
            this.$router.push({ path: '/news', query: { tit: '教育咨询', cur: '0' }})
        },
        hrefHydt() {
            this.$router.push({ path: '/news', query: { tit: '行业动态', cur: '1' }})
        },
    },
    created() {
        this.getId()
        this.getDetail()
    },
    mounted() {
    },
}
</script>

<style lang="less" scoped>
/* @import url("./less/style.less"); */
.lvya-newsDetails{
    width: 100%;
    height: 100%;
    background: url('../../../../public/static/lvya/news/banner5.png') no-repeat center top;
    background-size: 100% auto;
    .newsDetails-top{
        width: 1200px;
        margin: 0px auto;
        display: flex;
        justify-content: center;
        padding: 150px 0 0 0;
    }
    .newsDetails-top p{
        padding: 10px 20px;
        font-size: 18px;
        cursor: pointer;
    }
    .details-div{
        width: 1200px;
        min-height: 650px;
        border: 1px solid #eee;
        border-radius: 5px;
        margin: 20px auto;
        background: #fff;
        padding: 20px;
        box-sizing: border-box;
        color: #666;
    }
    .details-p1{
        font-size: 18px;
        color: #333;
        text-align: center;
        padding: 10px 0;
    }
    .details-p2{
        font-size: 14px;
        text-align: right;
        border-bottom: 1px solid #eee;
        padding-bottom: 10px;
        margin-bottom: 10px;
    }
    .details-p3{
        font-size: 14px;
        line-height: 28px;
        text-indent: 30px;
    }
    .news-tab{
        width: 1200px;
        margin: 0px auto;
        border-radius: 5px;
        padding: 30px 0;
    }
    .news-tab .tab-tilte{
        width: 260px;
        display: flex;
        justify-content: space-between;
        margin: 0 auto;
    }
    .news-tab .tab-tilte li{
        padding: 10px 0;
        text-align: center;
        cursor: pointer;
        font-size: 20px;
    }
    /* 点击对应的标题添加对应的背景颜色 */
    .news-tab .tab-tilte .active{
        border-bottom: 2px solid #05b21a;
        box-sizing: border-box;
    }
    .news-tab .tab-content div{
        text-align: left;
    }
}
</style>
